import "./styles.css";
import { createRoot } from "react-dom/client";
import { useEffect, useState } from "react";
import Counter from "./components/Counter";
import Tilt from 'react-parallax-tilt';
import "./style.css";

function App() {
  const [rule, setRule] = useState('Clique para começar');
  const [firstClick, setFirstClick] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const frases = [
    "Escolha 2 pessoas pra ficar de mãos dadas até o fim do jogo",
    "Escolha uma pessoa pra beber uma dose",
    "Escolha uma pessoa pra beber duas doses",
    "Diga quem é a pessoa mais bonita do grupo",
    "Aponte com quem você já ficou no grupo",
    "Pule sua vez",
    "Beba uma dose",
    "Beba duas doses",
    "Tire uma foto com alguém desconhecido",
    "Abrace a pessoa que você mais odeia do grupo",
    "Ligue para seu/sua ex",
    "Não fale o verbo beber até o final do jogo ou até beber",
    "Elogie alguém do grupo",
    "Finga pedir alguém em casamento",
    "Todos os homens bebem",
    "Todas as mulheres bebem",
    "Todos bebem",
    "O mais velho bebe",
    "O mais baixo bebe",
    "O mais alto bebe",
    "O mais novo bebe",
    "Loiros(as) bebem",
    "Ruivos(as) bebem",
    "A pessoa com aniversário mais próximo bebe",
    "O mais rico bebe",
    "Aponte para alguem que quer ficar OU que secretamente gosta de Furrys",
    "Faça o L",
    "Os flamenguistas bebem",
    "A última pessoa a falar o próprio nome completo bebe",
    "Todos com brinco bebem",
    "Todos em pé bebem",
    "Todos com franja bebem",
    "Todos de cabelo castanho bebem",
    "A pessoa à sua direita bebe",
    "A pessoa à sua esquerda bebe",
    "Escolha duas pessoas, quem piscar primeiro bebe",
    "Toda vez que você for beber, agradeça a Baco o deus romano do vinho ou beba dobrado, até o final do jogo",
    "Pessoas de olhos azuis bebem",
    "Todos com 10 dedos na mão bebem",
    "Pessoas com tatuagem bebem",
    "Todos que têm gato bebem",
    "Todos que têm cachorro bebem",
    "Pessoas com nome que começam em vogal bebem",
    "Todos que já viajaram pra fora do país bebem",
    "Fale o nome de todos os jogadores ou beba",
    "Xingue um famoso no Twitter ou beba",
    "Tire foto com um DJ ou beba",
    "Beba e aponte alguém pra beber com você",
    "Escolha duas pessoas, elas bebem ou se beijam",
    "Escolha três pessoas, elas se beijam ou bebem",
    "Escolha duas pessoas, elas não bebem na próxima vez",
    "Escolha uma pessoa, ela não bebe na próxima vez",
    "Todos de camisa bebem",
    "Faça um Pix para um amigo de 1 centavo e diga que sente falta dele",
    "Todos que já respiraram bebem",
    "Aponte para alguém que beija bem OU secretamente é uma criatura mitológica"
  ];


  const newCard = () => {
    if (isLoading) return;
    setIsLoading(true);
    if (firstClick){
      setFirstClick(false);
      const card = document.querySelector('.card');
      if (card) {
        card.classList.remove("hidden");
        card.classList.add("entrance");
        setTimeout(() => {
          card.classList.remove("entrance");
          setIsLoading(false);
        }, 2000);
      }
    }else{
      const card = document.querySelector('.card');
      if (card) {
        card.classList.add("turn-around");
        setTimeout(() => {
          setRule("");
        }, 100);

        setTimeout(() => {
          setRule(frases[Math.floor(Math.random() * frases.length)]);
          setIsLoading(false);
        }, 1200);

        setTimeout(() => {
          card.classList.remove("turn-around");
        }, 1000);
      }
    }
  }

  return (
    <main onClick={newCard} className="container">

      {firstClick && <h2 className="title">Chaina</h2>}
      {firstClick && <h3 className="subtitle">Faz ou bebe <br /> <br />não tem muita regra pra esse jogo, se vira ai!</h3>}
      <Tilt
        gyroscope={true}
        trackOnWindow={true}
        perspective={500}
        className="tilt"
      >
        <div className="card hidden">
          <h2 className="rule">{rule}</h2>
          {!isLoading && <p className="instagram">@Cha1n4</p>}
        </div>
      </Tilt>

      {/* <Counter /> */}
    </main>
  );
}

createRoot(document.getElementById("app")!).render(<App />);
